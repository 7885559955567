@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 15px;
}

.post-content p {
  text-align: justify;
}

.post-content p+p, p+ul, ul+p {
  margin-top: 10px;
}

.post-content a {
  color: #007bff;
}

.post-content .text-black {
  color: black;
}

.post-content a:hover {
  color: #0056b3;
}

.post-content ul {
  list-style: disc;
  list-style-position: outside;
  padding-left: 30px;
}

.post-content ul.list-none {
  list-style: none;
  list-style-position: unset;
  padding-left: unset;
}

.post-content li+li {
  margin-top: 7px;
}

.post-content h3 {
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 20px;
}

.post-content h4 {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 15px;
}

.event-bio p+p {
  margin-top: 5px;
}

.event-bio ul {
  list-style: disc;
  list-style-position: outside;
  padding-left: 15px;
}